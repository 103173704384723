// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import bonkImage from './bonk.png';

function Navbar({ userName, handleLogout, handleLoginSuccess, handleLoginFailure, toggleDarkMode, isDarkMode }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <a className="navbar-brand d-flex align-items-center" href="/" style={{ marginRight: 'auto' }}>
          <img src={bonkImage} alt="Logo" style={{ width: '70px', marginRight: '10px' }} />
          engiechat
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto d-flex align-items-center">
            {userName ? (
              <>
                <li className="nav-item">
                  <span className="nav-link">Welcome, {userName}!</span>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/viewer">Viewer</Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/reader">See Demos</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/upload">Upload a Demo</Link> {/* New Upload link */}
                </li>
                <li className="nav-item">
                  <button onClick={handleLogout} className="btn btn-danger nav-link">
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={handleLoginFailure}
                  render={renderProps => (
                    <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-primary nav-link">
                      Login
                    </button>
                  )}
                />
              </li>
            )}
            <li className="nav-item">
              <button onClick={toggleDarkMode} className="btn btn-outline-light nav-link">
                <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
