import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import bonkImage from './bonk.png';
import Viewer from './Viewer';
import Reader from './Reader';
import Upload from './Upload';
import ProtectedRoute from './ProtectedRoute';
import DemoEvents from './DemoEvents';

function App() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const [givenEmail, setGivenEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const savedEmail = Cookies.get('userEmail');
    if (savedEmail) {
      fetch(`${BACKEND_URL}/users/${savedEmail}`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwt')}`
        }
      })
        .then(response => response.json())
        .then(user => {
          setUserName(user.username);
          setGivenEmail(savedEmail);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          Cookies.remove('userEmail');
          Cookies.remove('jwt');
        });
    }
  }, [BACKEND_URL]);


  const handleLoginFailure = () => {
    console.log('Login Failed');
  };

  const handleLogout = () => {
    Cookies.remove('jwt');
    Cookies.remove('userEmail');
    Cookies.remove('userName');
    setUserName(null);
    setGivenEmail(null);
    setShowForm(false);
  };

  const handleLoginSuccess = async (response) => {
    const decodedToken = jwtDecode(response.credential);  // Decoding the Google ID token directly
    const googleId = decodedToken.sub;  // Extracting the Google ID
    setGivenEmail(decodedToken.email);

    try {
      const res = await fetch(`${BACKEND_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken: response.credential }),
      });

      const allowedEmails = ['co2020mac@gmail.com', 'rflei075@gmail.com', 'cmitc10@gmail.com']; // Replace with your list of allowed emails

      if (res.ok) {
        const { jwt, username } = await res.json();
        setUserName(username);
        Cookies.set('jwt', jwt, { secure: true, sameSite: 'strict' });
        Cookies.set('userEmail', decodedToken.email);
        Cookies.set('userName', username);
      } else if (res.status === 404 && allowedEmails.includes(decodedToken.email)) {
        // If the user is not found, but the email is allowed, prompt for a username
        console.log('User not found, but email is in the allowed list. Prompting to create an account');
        setShowForm(true); // Show the form to create a new account

        // Store the googleId for use when submitting the form
        Cookies.set('google_id', googleId);
      } else {
        console.log('User not found and email is not allowed to create an account');
        setShowForm(false); // Do not show the form
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.elements.username.value;

    const regex = /^[a-zA-Z0-9._-]{3,25}$/;

    if (!regex.test(username)) {
      alert('Invalid username. Usernames must have at least three characters, be alphanumeric, can include hyphens, periods, and underscores, and cannot be more than 25 characters.');
      return;
    }

    const googleId = Cookies.get('google_id');  // Retrieve the stored Google ID
    if (!googleId) {
      console.error('Google ID is missing.');
      return;
    } else {
      console.log('Google ID:', googleId);
    }

    if (givenEmail) {
      try {
        const response = await fetch(`${BACKEND_URL}/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: username,
            email: givenEmail,
            google_id: googleId,  // Include the Google ID in the request
          }),
        });

        if (response.ok) {
          const { jwt } = await response.json(); // Get the JWT from the server

          setUserName(username);
          setShowForm(false);
          Cookies.set('jwt', jwt, { secure: true, sameSite: 'strict' }); // Store the JWT
          Cookies.set('userEmail', givenEmail);
          Cookies.set('userName', username);
        } else {
          console.error('Failed to create user:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.error('Email or Google ID is missing.');
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router>
        <div className={isDarkMode ? "App dark-mode" : "App light-mode"}>
        <Navbar
            userName={userName}
            handleLogout={handleLogout}
            handleLoginSuccess={handleLoginSuccess}
            handleLoginFailure={handleLoginFailure}
            toggleDarkMode={toggleDarkMode}
            isDarkMode={isDarkMode}
          />


          <Routes>
            <Route path="/" element={
              <div className="container mt-5">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">is somebody gonna push da freakin cart?</h5>
                  </div>
                </div>

                {showForm && (
                  <div className="card mt-3">
                    <div className="card-body">
                      <h2>Create a New Account</h2>
                      <form id="usernameForm" onSubmit={handleSubmit}>
                        <label>
                          Username:&nbsp;
                          <input type="text" name="username" required />
                        </label>
                        <button type="submit" className="btn btn-primary mt-2">Submit</button>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            } />
            <Route path="/viewer" element={<ProtectedRoute><Viewer /></ProtectedRoute>} />
            <Route path="/reader" element={<ProtectedRoute><Reader /></ProtectedRoute>} />
            <Route path="/upload" element={<ProtectedRoute><Upload /></ProtectedRoute>} /> {/* New Upload route */}
            <Route path="/demo/:filename" element={<ProtectedRoute><DemoEvents /></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
