import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';
import Navbar from './Navbar';

function Reader() {
  const [userName, setUserName] = useState(null);
  const [demos, setDemos] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserName = Cookies.get('userName');
    if (savedUserName) setUserName(savedUserName);

    const savedTheme = Cookies.get('theme') || 'dark';
    setIsDarkMode(savedTheme === 'dark');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/demos`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
      },
    })
      .then(response => response.json())
      .then(data => setDemos(data))
      .catch(error => console.error('Error fetching demos:', error));
  }, []);

  const handleLogout = () => {
    Cookies.remove('jwt');
    Cookies.remove('userName');
    setUserName(null);
    navigate('/');
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    Cookies.set('theme', isDarkMode ? 'light' : 'dark');
  };

  const handleGoClick = (filename) => {
    navigate(`/demo/${filename}`);
  };
  

  return (
    <div className={`Reader ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Navbar
        userName={userName}
        handleLogout={handleLogout}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
      />
      <div className="content" style={{ maxWidth: '80%', margin: '0 auto', padding: '20px' }}>
        <h1>Available Demos</h1>
        <div>
          <h2>Demo List</h2>
          <div className="table-responsive">
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Date Uploaded</th>
                  <th>Uploader</th>
                  <th>Text</th>
                  <th>Voice</th>
                  <th>Go</th>
                </tr>
              </thead>
              <tbody>
                {demos.map((demo, index) => (
                  <tr key={index}>
                    <td>{demo.filename}</td>
                    <td>{demo.date_uploaded}</td>
                    <td>{demo.uploader}</td>
                    <td>{demo.text_processed ? 'Yes' : 'No'}</td>
                    <td>{demo.voice_processed ? 'Yes' : 'No'}</td>
                    <td>
                      <button
                        onClick={() => handleGoClick(demo.filename)}
                        className="btn btn-primary"
                        disabled={!demo.text_processed}
                        style={{
                          backgroundColor: !demo.text_processed ? 'grey' : '',
                          cursor: !demo.text_processed ? 'not-allowed' : 'pointer',
                        }}
                      >
                        GO!
                      </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reader;
