import React, { useState } from 'react';
import Cookies from 'js-cookie';

function Upload() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (!selectedFile.name.endsWith('.dem')) {
        setErrorMessage("Only .dem files are allowed.");
        setFile(null);
      } else if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
        setErrorMessage("File size must be less than 100 MB.");
        setFile(null);
      } else {
        setErrorMessage("");
        setFile(selectedFile);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      try {
        // Get the username from the JWT token
        const token = Cookies.get('jwt');
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const username = decodedToken.username;
        const filename = `${username}-${file.name}`;

        // Preliminary check to see if the file already exists
        const checkResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-file`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwt')}`
          },
          body: JSON.stringify({ filename })
        });

        const checkData = await checkResponse.json();

        if (checkResponse.status === 400 && checkData.message === 'We already have this file.') {
          setErrorMessage('This file has already been uploaded.');
          return;  // Halt the upload process
        }

        // Proceed with file upload
        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.REACT_APP_BACKEND_URL}/upload`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${Cookies.get('jwt')}`);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(percentCompleted);
          }
        };

        xhr.onload = () => {
          console.log('XHR Response:', xhr.responseText); // Log response for debugging
          console.log('XHR Status:', xhr.status); // Log status for debugging

          if (xhr.status === 200 || xhr.status === 201) { // Consider 201 as a success status
            try {
              const responseData = JSON.parse(xhr.responseText);
              console.log('Parsed Response Data:', responseData); // Log parsed response
              setErrorMessage(""); // Clear any previous error message
              setSuccessMessage("Upload complete! Thanks"); // Set success message when complete
            } catch (e) {
              console.error('Failed to parse JSON response:', e);
              setErrorMessage('Unexpected response format from server.');
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              if (xhr.status === 400 && errorData.message === 'We already have this file.') {
                setErrorMessage('This file has already been uploaded.');
                return; // Stop further execution
              } else {
                setErrorMessage('File upload failed');
              }
            } catch (e) {
              console.error('Failed to parse JSON error response:', e);
              setErrorMessage('Unexpected error response format from server.');
            }
            setSuccessMessage(""); // Clear any previous success message
          }
        };

        xhr.onerror = () => {
          console.error('Error uploading file');
          setErrorMessage('Error uploading file');
          setSuccessMessage(""); // Clear any previous success message
        };

        xhr.send(formData);
      } catch (error) {
        console.error('Error during preliminary check:', error);
        setErrorMessage('Error during preliminary check. Please try again later.');
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Upload .dem File</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fileInput">Choose a .dem file to upload:</label>
              <input
                type="file"
                id="fileInput"
                className="form-control"
                onChange={handleFileChange}
                accept=".dem"
              />
            </div>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {uploadProgress > 0 && (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {uploadProgress}%
                </div>
              </div>
            )}
            {successMessage && <div className="alert alert-success mt-2">{successMessage}</div>}
            <button type="submit" className="btn btn-primary" disabled={!file}>
              Upload
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Upload;
