import React from 'react';

export function PlayerList({ players }) {
    // Track names we've already added to prevent duplicates
    const addedNames = new Set();

    return (
        <div className="player-list">
            <div className="team red">
                <h3>Red Team</h3>
                {Object.values(players)
                    .filter(player => player.team === 'red')
                    .filter(player => {
                        if (addedNames.has(player.name)) {
                            return false; // If name is already added, skip this player
                        }
                        addedNames.add(player.name);
                        return true;
                    })
                    .map((player, index) => (
                        <div key={index} className={`player-name player-${player.steamId.replace(/[^a-zA-Z0-9]/g, '')}`}>
                            {player.name}
                        </div>
                    ))}
            </div>
            <div className="team blue">
                <h3>Blue Team</h3>
                {Object.values(players)
                    .filter(player => player.team === 'blue')
                    .filter(player => {
                        if (addedNames.has(player.name)) {
                            return false; // If name is already added, skip this player
                        }
                        addedNames.add(player.name);
                        return true;
                    })
                    .map((player, index) => (
                        <div key={index} className={`player-name player-${player.steamId.replace(/[^a-zA-Z0-9]/g, '')}`}>
                            {player.name}
                        </div>
                    ))}
            </div>
        </div>
    );
}
