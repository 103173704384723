import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

function DemoEvents() {
  const { filename } = useParams();
  const [events, setEvents] = useState([]);
  const [audioSources, setAudioSources] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/demo/${filename}/events`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwt')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Received data:', data);  // Log the received data
        setEvents(data);
        fetchAudioSources(data);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, [filename]);

  const fetchAudioSources = async (events) => {
    const sources = {};
    for (const event of events) {
      if (event.file_path) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/audio-proxy/${encodeURIComponent(event.file_path)}`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwt')}`,
          },
        });
        const blob = await response.blob();
        sources[event.tick] = URL.createObjectURL(blob);
      }
    }
    setAudioSources(sources);
  };

  return (
    <div className="content" style={{ maxWidth: '80%', margin: '0 auto', padding: '20px' }}>
      <h1>Events for {filename}</h1>
      <div className="table-responsive">
        <table className="table table-striped table-bordered text-center">
          <thead>
            <tr>
              <th>Tick</th>
              <th>Type</th>
              <th>Client</th>
              <th>Chat</th>
              <th>Play</th> {/* Add a column for the play button */}
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.tick}</td>
                <td>{event.type}</td>
                <td>{event.type === 'chat' ? event.from : event.client || '-'}</td>
                <td>{event.type === 'chat' ? event.chat_text : event.text}</td>
                <td>
                  {audioSources[event.tick] ? (
                    <audio controls>
                      <source src={audioSources[event.tick]} type="audio/wav" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DemoEvents;
