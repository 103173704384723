import React, { useEffect, useState } from 'react';

export function ChatBubble({ chatEvents, currentTick, playerData }) {
    const [visibleChats, setVisibleChats] = useState([]);

    useEffect(() => {
        // Filter and show chat messages that should be visible
        const newVisibleChats = chatEvents.filter(event => event.tick <= currentTick);
        setVisibleChats(prevChats => {
            // Add new chats, ensuring no duplicates
            const updatedChats = [...prevChats];
            newVisibleChats.forEach(chat => {
                if (!prevChats.some(c => c.id === chat.id)) {
                    updatedChats.push(chat);
                }
            });
            return updatedChats;
        });

        // Set a timeout to remove the chat bubble after 5 seconds
        newVisibleChats.forEach(chat => {
            setTimeout(() => {
                setVisibleChats(prev => prev.filter(c => c.id !== chat.id));
            }, 5000); // 5 seconds
        });
    }, [currentTick, chatEvents]);

    return (
        <div className="chat-bubbles">
            {visibleChats.map((chat, index) => {
                const player = Object.values(playerData).find(p => p.name === chat.from);
                const playerClass = `player-${player?.steamId?.replace(/[^a-zA-Z0-9]/g, '')}`;

                if (!player) return null;

                const playerElement = document.querySelector(`.${playerClass}`);
                const playerRect = playerElement?.getBoundingClientRect();
                const bubbleOffset = 200; // Adjust as needed for bubble width

                const isRightSide = player.team === 'blue';

                let positionStyle = {};

                if (playerRect) {
                    if (isRightSide) {
                        positionStyle.left = Math.min(playerRect.right + 10, window.innerWidth - bubbleOffset) + 'px';
                    } else {
                        positionStyle.right = Math.max(window.innerWidth - playerRect.left + 10, bubbleOffset) + 'px';
                    }

                    positionStyle.top = playerRect.top + window.scrollY + 'px';
                }

                return (
                    <div
                        key={chat.id || index} // Ensure unique key
                        className={`chat-bubble ${isRightSide ? 'right' : 'left'}`}
                        style={{
                            position: 'absolute',
                            ...positionStyle,
                        }}
                    >
                        <p><strong>{chat.from}:</strong> {chat.text}</p>
                    </div>
                );
            })}
        </div>
    );
}
