import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import yaml from 'js-yaml';
import './App.css';
import { PlayerList } from './PlayerList';
import { ChatBubble } from './ChatBubble';
import Cookies from 'js-cookie';

function Viewer() {
    const [playerData, setPlayerData] = useState({});
    const [chatEvents, setChatEvents] = useState([]);
    const [tick, setTick] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState(1); // Speed multiplier, 1x by default

    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Check if the user is authenticated
        const userEmail = Cookies.get('userEmail');
        if (!userEmail) {
            navigate('/'); // Redirect to main page if not authenticated
        } else {
            console.log('User is authenticated:', userEmail);
        }

        // Load YAML data
        fetch('/hello.yaml')
            .then(response => response.text())
            .then(text => {
                const data = yaml.load(text);
                setPlayerData(data.player_names);
                setChatEvents(data.events.filter(event => event.type === 'chat'));
            });
    }, [navigate]);

    useEffect(() => {
        if (isPlaying) {
            // Calculate interval duration for faster base speed
            const intervalDuration = 100 / (1000 * speed); // Base speed is 0.1ms per tick
            const interval = setInterval(() => {
                setTick(prevTick => prevTick + 1); // Always increment by 1
            }, intervalDuration); // Set interval based on calculated duration
            return () => clearInterval(interval);
        }
    }, [isPlaying, speed]); // Re-run the effect when speed changes

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const handleSpeedChange = (event) => {
        setSpeed(Number(event.target.value));
    };

    const currentTime = (tick * 0.001).toFixed(3); // Convert ticks to seconds

    return (
        <div className="Viewer">


            <div className="content container mt-5">
                <div className="timer mb-3">
                    <h2>Tick: {tick}, Time: {currentTime} seconds</h2>
                </div>
                <div className="controls">
                    <label htmlFor="speed">Speed: {speed}x</label>
                    <input
                        type="range"
                        id="speed"
                        min="1"
                        max="3"
                        step="1"
                        value={speed}
                        onChange={handleSpeedChange}
                    />
                </div>
                <PlayerList players={playerData} />
                <ChatBubble chatEvents={chatEvents} currentTick={tick} playerData={playerData} />
            </div>
        </div>
    );
}

export default Viewer;
